/* -------------------------------------------------------------------------- */
/*                                    Fonts                                   */
/* -------------------------------------------------------------------------- */

@font-face {
  font-family: "gotham-pro";
  src: url("../../public/fonts/gotham-pro-regular/Gotham\ Pro\ Regular.otf");
}

$font-family: "gotham-pro";

@font-face {
  font-family: "gotham-pro-light";
  src: url("../../public/fonts/gotham-pro-light/Gotham\ Pro\ Light.otf");
}

$font-family-light-regular: "gotham-pro-light";

:export {
  crFontPrimary: $font-family;
  crFontSecondary: $font-family-light-regular;
}
